import axios from 'axios';
import {debounce} from 'lodash';

window.addEventListener('load', () => {
        const productItemContainer = document.querySelector('.product-item-container');
        const productItems = document.querySelector('.product-item-container .product-items');
        const productItemLoadMoreButtons = document.querySelectorAll('button[data-action="load-products"]');

        if (productItemContainer) {

            const searchBox = document.querySelector('input[name="search_term"]');
            const sortBy = document.querySelector('select[name="sort_by"]');

            ['click', 'touch'].forEach(eventListener => {
                productItemLoadMoreButtons.forEach(productLoadMoreButtonElement => {
                    productLoadMoreButtonElement.addEventListener(eventListener, loadMoreProducts);
                });
            })
            let allProductsLoaded = false;

            const filters = {
                search: null,
                sort: 'alpha-a'
            };

            if (searchBox) {
                searchBox.addEventListener('input', debounce((event) => {
                    console.log('Search term changed: ' + event.target.value);

                    filters.search = event.target.value;

                    resetAndFetchProducts();

                }, 250));
            }

            if (sortBy) {
                sortBy.addEventListener('change', (event) => {
                    console.log('Sort by changed: ' + event.target.value);

                    filters.sort = event.target.value;

                    resetAndFetchProducts();
                });
            }

            if (productItemContainer) {
                console.log('Product container found, loading products...');

                /**
                 * As we know the product loader is found on the page add the scroll event to the document
                 */
                document.addEventListener('scroll', handleScroll);
                handleScroll();
            }

            function handleScroll() {
                if (document.documentElement.scrollHeight === document.documentElement.scrollTop + window.innerHeight) {
                    console.log('Bottom of page, load more products...');
                    loadMoreProducts();
                }
            }

            function resetAndFetchProducts() {
                /**
                 * Get all the products currently on page
                 * @type {NodeListOf<Element>}
                 */
                const existingProducts = document.querySelectorAll('.product-item-container .product-item[data-product-id]');

                existingProducts.forEach(product => product.remove());

                allProductsLoaded = false;

                loadMoreProducts();
            }

            function loadMoreProducts() {
                if (allProductsLoaded) {
                    console.log('Not loading more products as they have been marked as all loaded.');
                    return;
                }

                console.log('Hitting products endpoint...');

                /**
                 * Empty array of excluded ID's
                 * @type array
                 */
                const excludedIds = [];

                /**
                 * Get all the products currently on page
                 * @type {NodeListOf<Element>}
                 */
                const existingProducts = document.querySelectorAll('.product-item-container .product-item[data-product-id]');

                /**
                 * Loop the products and build the array of excluded product ID's (already shown on page)
                 */
                existingProducts.forEach(product => {
                    excludedIds.push(product.getAttribute('data-product-id'));
                })

                /**
                 * Make the request for more products
                 */
                axios.get(window.location.origin + '/products.json', {
                    params: {
                        excludedIds: encodeURI(excludedIds),
                        sortBy: filters.sort ?? 'alpha-a',
                        searchTerm: filters.search ?? null
                    },
                }).then((response) => {
                    console.log('Got a response!', response.data, response.data.data.length, response.data.meta.pagination.per_page);

                    if (response.data.data.length === 0 || response.data.data.length < response.data.meta.pagination.per_page) {
                        console.log('All products have been loaded in, no more to process!');
                        allProductsLoaded = true;
                        productItemLoadMoreButtons.forEach(productLoadMoreButtonElement => {
                            productLoadMoreButtonElement.innerHTML = 'All Loaded';
                            productLoadMoreButtonElement.setAttribute('disabled', 'disabled');
                        });
                    }

                    response.data.data.forEach(product => {
                        const productElement = document.createElement('div');
                        productElement.innerHTML = product.html_content;
                        productElement.firstChild.className = productElement.firstChild.className + ' hide';

                        const newProductItemChild = productItems.appendChild(productElement.firstChild);

                        setTimeout(() => {
                            newProductItemChild.classList.remove('hide');
                        }, 100);
                    });


                }).catch((error) => {
                    console.log('error', error, error.response);
                });
            }
        }
    }
);