import Hls from "hls.js";

window.addEventListener('DOMContentLoaded', () => {
    const hlsPlayers = document.querySelectorAll('video[data-hls-src]');

    if(hlsPlayers) {

        console.log('HLS Players found.');

        const hlsSupported = Hls.isSupported();

        console.log('HLS supported: ' + hlsSupported);

        hlsPlayers.forEach(videoElement => {
            const videoSrc = videoElement.getAttribute('data-hls-src');

            console.log(videoElement, 'Video Src: ' + videoSrc);

            if(hlsSupported) {
                const hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(videoElement);
            } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                videoElement.src = videoSrc;
            }

        });
    }
});