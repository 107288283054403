require('bootstrap');

window.Popper = require('@popperjs/core');

window.axios = require('axios');
window.axios.defaults.headers.common['X-CSRF-Token'] = document.head.querySelector('meta[name="x-csrf-token"]').content;

import './components/hls-players';

import './pages/contact';
import './pages/news';
import './pages/products';

(function(){
    const headerTop = document.querySelector('header div.header-top');
    const headerBottom = document.querySelector('header div.header-bottom');
    const navContainer = document.querySelector('header div.header-bottom div.nav-container');
    const videoContainer = document.querySelector('header div.header-bottom div.banner div.video-container');
    const videoElement = document.querySelector('header div.header-bottom div.banner div.video-container video');
    const bannerWrapper = document.querySelector('header div.header-bottom div.banner div.banner-wrapper');
    const navContainerBackground = document.querySelector('header div.header-bottom div.nav-container div.nav-background');

    if(headerTop && headerBottom && navContainer && navContainerBackground) {
        navContainer.style.top = headerTop.getBoundingClientRect().height + 'px';

        const navScrollThreshold = headerBottom.getBoundingClientRect().height - (navContainer.getBoundingClientRect().height * 1.5);
        const scrollStep = 100 / navContainer.getBoundingClientRect().height;

        window.addEventListener('scroll', () => {
            if(window.scrollY >= headerBottom.getBoundingClientRect().height - navContainer.getBoundingClientRect().height) {
                navContainer.classList.add('show-background');
            } else {
                navContainer.classList.remove('show-background');
            }

            const scrollCount = window.scrollY - navScrollThreshold > 0 ? window.scrollY - navScrollThreshold : 0;
            const scrollOpacity = (scrollCount * scrollStep) / 100;

            navContainerBackground.style.opacity = scrollOpacity >= 1 ? 1 : scrollOpacity;
        })
    }

    if(videoContainer && headerBottom && bannerWrapper) {
        ['scroll', 'DOMContentLoaded'].forEach(windowEventListener => {
            window.addEventListener(windowEventListener, () => {
                videoContainer.style.minHeight = headerBottom.getBoundingClientRect().height + 'px';
                bannerWrapper.style.minHeight = headerBottom.getBoundingClientRect().height + 'px';
            });
        });

        window.addEventListener('resize', () => {
            const videoWidth = ((16 / 9) * headerBottom.getBoundingClientRect().height);

            console.log(videoWidth, window.innerWidth, videoWidth < window.innerWidth);
            if(videoWidth < window.innerWidth) {
                videoElement.style.width = '100%';
            } else {
                videoElement.style.width = videoWidth + 'px';
            }
        });
    }
})();
