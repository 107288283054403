window.addEventListener('DOMContentLoaded', () => {
    const contactForm = document.querySelector('#contactForm');

    if(contactForm) {
        contactForm.addEventListener('submit', (event) => {
            event.preventDefault();

            console.log('contact');

            const formData = new FormData(contactForm);

            axios.post('/', formData).then(response => {
                console.log('submit success', response.data);

                document.querySelector('.message-success').classList.remove('d-none');
                contactForm.classList.add('d-none');
            }).catch(error => {
                if(error.response.data.success === false) {
                    document.querySelector('.message-failed').classList.remove('d-none');
                }
            });
        });
    }
});