import {Fancybox} from "@fancyapps/ui";
import {debounce} from 'lodash';
import axios from "axios";

window.addEventListener('DOMContentLoaded', () => {
    const newsItemContainer = document.querySelector('.news-item-container');
    const newsItems = document.querySelector('.news-item-container .news-items');
    const newsItemsLoadMoreButtons = document.querySelectorAll('button[data-action="load-news"]');

    const innerNewsItems = document.querySelectorAll('.news-item-container .news-item .inner-news-item[data-src]');

    if (newsItemContainer) {

        const searchBox = document.querySelector('input[name="search_term"]');
        const sortBy = document.querySelector('select[name="sort_by"]');

        console.log('Product container found, loading news...');

        /**
         * As we know the product loader is found on the page add the scroll event to the document
         */
        document.addEventListener('scroll', handleScroll);
        handleScroll();

        innerNewsItems.forEach(newsItem => {
            ['click', 'touch'].forEach(eventListener => {
                newsItem.addEventListener(eventListener, (event) => {
                    openNews(newsItem);
                });
            });
        });

        function openNews(element) {
            Fancybox.show([{
                src: element.getAttribute('data-src'),
                slug: element.getAttribute('data-slug'),
                type: 'ajax'
            }], {
                mainClass: 'fancybox__container__max_width fancybox__news_item'
            });
        }

        if (window.location.hash) {
            console.log('hash: ' + window.location.hash);

            const element = document.querySelector('.news-item-container .news-item .inner-news-item[data-slug="' + window.location.hash.substring(1) + '"]');

            if (element) {
                openNews(element);
            }
        }

        ['click', 'touch'].forEach(eventListener => {
            newsItemsLoadMoreButtons.forEach(newsItemLoadMoreButton => {
                newsItemLoadMoreButton.addEventListener(eventListener, loadMoreNews);
            });
        })

        let allNewsLoaded = false;

        const filters = {
            search: null,
            sort: 'alpha-a'
        };

        if (searchBox) {
            searchBox.addEventListener('input', debounce((event) => {
                console.log('Search term changed: ' + event.target.value);

                filters.search = event.target.value;

                resetAndFetchNews();

            }, 250));
        }

        if (sortBy) {
            sortBy.addEventListener('change', (event) => {
                console.log('Sort by changed: ' + event.target.value);

                filters.sort = event.target.value;

                resetAndFetchNews();
            });
        }

        function handleScroll() {
            if (document.documentElement.scrollHeight === document.documentElement.scrollTop + window.innerHeight) {
                console.log('Bottom of page, load more news...');
                loadMoreNews();
            }
        }

        function resetAndFetchNews() {
            /**
             * Get all the news items currently on page
             * @type {NodeListOf<Element>}
             */
            const existingNewsItems = document.querySelectorAll('.news-item-container .news-items .news-item[data-news-id]');

            existingNewsItems.forEach(newsItem => newsItem.remove());

            allNewsLoaded = false;

            loadMoreNews();
        }

        function loadMoreNews() {
            if (allNewsLoaded) {
                console.log('Not loading more news as they have been marked as all loaded.');
                return;
            }

            console.log('Hitting news endpoint...');

            /**
             * Empty array of excluded ID's
             * @type array
             */
            const excludedIds = [];

            /**
             * Get all the news currently on page
             * @type {NodeListOf<Element>}
             */
            const existingNewsItems = document.querySelectorAll('.news-item-container .news-item[data-news-id]');

            /**
             * Loop the news and build the array of excluded product ID's (already shown on page)
             */
            existingNewsItems.forEach(newsItem => {
                excludedIds.push(newsItem.getAttribute('data-news-id'));
            })

            /**
             * Make the request for more news
             */
            axios.get(window.location.origin + '/news.json', {
                params: {
                    excludedIds: encodeURI(excludedIds),
                    sortBy: filters.sort ?? 'alpha-a',
                    searchTerm: filters.search ?? null
                },
            }).then((response) => {
                console.log('Got a response!', response.data, response.data.data.length, response.data.meta.pagination.per_page);

                if (response.data.data.length === 0 || response.data.data.length < response.data.meta.pagination.per_page) {
                    console.log('All news have been loaded in, no more to process!');
                    allNewsLoaded = true;
                    newsItemsLoadMoreButtons.forEach(newsItemLoadMoreButton => {
                        newsItemLoadMoreButton.innerHTML = 'All Loaded';
                        newsItemLoadMoreButton.setAttribute('disabled', 'disabled');
                    });
                }

                response.data.data.forEach(newsItem => {
                    const newsItemElement = document.createElement('div');
                    newsItemElement.innerHTML = newsItem.html_content;
                    newsItemElement.firstChild.className = newsItemElement.firstChild.className + ' hide';

                    const newProductItemChild = newsItems.appendChild(newsItemElement.firstChild);

                    setTimeout(() => {
                        newProductItemChild.classList.remove('hide');
                    }, 100);
                });


            }).catch((error) => {
                console.log('error', error, error.response);
            });
        }
    }
});